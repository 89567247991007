<template lang="pug">
v-card-text.pt-8.full-height.px-4
          //- DURATION MIN
          InputRow(title="Dauer" icon="$duration" v-if="isVisible('duration')")
            SelectDialog.mt-md-7.mt-6(slot="inputSlot"  label="Dauer" :items='durationItems' v-model='experienceItem.durationYears' formating="years"  :multiple="false"  :required="false")

          //- AREAS
          InputRow(title="Bereich" icon="$area" v-if="isVisible('areas')")
              SelectAreaMenu(slot="inputSlot" :areaItems="experienceItem.areas" :required="true" @areaChanged="areaItemsChanged")
        
          //- MANAGEMENT 
          InputRow(title="Level"  icon="$country" v-if="isVisible('level')")
              SelectDialog(slot="inputSlot" label="Level"  :items='ExperienceLevels' v-model='experienceItem.level' :multiple="true"   :required="true" :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']")
          //- ALL OTHER COUNTRIES
          InputRow(title="Andere Länder" icon="$country" v-if="isVisible('otherCountries')")
              v-checkbox(slot="inputSlot" hide-details label="Alle Länder außer Land der Staatsangehörigkeit" @change="() => { formRef.validate()}" v-model="experienceItem.allOtherCountries" :rules=" experienceItem.countries.length > 0 ? undefined : [v => v || 'Pflichtfeld']")
         
          //- COUNTRY
          InputRow(title="Land" icon="$country" v-if="isVisible('country')")
              SelectDialog(slot="inputSlot" label="Land" :items='countries' v-model='experienceItem.countries' :required="true" :searchItems="true" :returnObject="true" :multiple="true"  itemText="name" itemValue="alpha2" :rules="this.experienceItem.allOtherCountries ? undefined : [v => ( v && v.length > 0) || 'Pflichtfeld']")
</template>

<script>
import Vue from 'vue';
import InputRow from '@/components/inputs/InputRow';
import { cloneDeep } from 'lodash';
import { countries } from '@/assets/selections/countries';
import SelectDialog from '@/components/dialogs/SelectDialog.vue';
import { generateArrayRange } from '@/utilities/Utils';
import SelectAreaMenu from '@/components/dialogs/SelectAreaDialog.vue';
import { showAttribute } from '@/types/experience';
import ExperienceLevels from '@/assets/selections/experienceLevels';

export default Vue.extend({
  components: { InputRow, SelectAreaMenu, SelectDialog },
  props: ['value', 'indexToUpdate', 'type', 'valid', 'formRef'],
  data() {
    return {
      index: cloneDeep(this.indexToUpdate),
      ExperienceLevels: ExperienceLevels.offering,
      countries: countries,
      experienceItem: this.value[this.indexToUpdate]
        ? cloneDeep(this.value[this.indexToUpdate])
        : {
            activity: '',
            countries: [],
            timeEnd: '',
            timeStart: '',
            level: [],
            title: '',
            type: this.type,
            areas: [],
          },
      search: null,
    };
  },
  computed: {
    durationItems() {
      return generateArrayRange(1, 10, 1);
    },
  },
  methods: {
    areaItemsChanged(areas) {
      this.experienceItem.areas = areas;
    },
    isVisible(row) {
      return showAttribute(this.experienceItem, row, true, true);
    },
  },
  watch: {
    select() {
      this.search = '';
    },
    experienceItem: {
      handler() {
        if (this.value[this.index]) {
          this.value[this.index] = this.experienceItem;
        } else {
          this.value.push(this.experienceItem);
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
</style>
