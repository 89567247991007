<template lang="pug">
v-card-text.flex-grow-1.px-0.pt-0.pb-0
          v-text-field.mx-4.mt-4.mb-2(v-model='search' dense outlined hide-details label='Suche' rounded append-icon='mdi-magnify')
          v-list.full-height(v-on:scroll.passive="onScroll" subheader :class='mobile ? "skill-list-mobile" : "skill-list-desktop"')
          v-list(v-on:scroll.passive="onScroll" subheader :class='mobile ? "skill-list-mobile" : "skill-list-desktop"')
            .skillSection(v-for='skill, index in filteredSkills')
              v-divider(v-if="index > 0")
              v-subheader(v-if='subheaderNeeded(skill, index)') {{skill.name[0]}} 
              v-list-item
                  v-list-item-content
                    div.d-flex.flex-row.justify-space-between.align-center.flex-wrap
                      div
                        v-list-item-title
                          span {{skill.name}} 
                      div.py-2
                        SkillLevel(v-if="isSelected(skill)" :value='value.find((i) => i.name === skill.name)' @input='levelChangedHandler' :readonly='false')
                  v-list-item-action
                    v-checkbox(@change='skillCheckboxChanged($event, skill)' :input-value='isSelected(skill)' :rules="[v => ( value.length > 0) || 'Pflichtfeld']") 
</template>

<script>
import Software from '@/assets/selections/software.json';
import Languages from '@/assets/selections/languages.json';
import { sortArray } from '@/utilities/Utils';
import SkillLevel from './SkillLevel';
import { SkillType } from '@/types/skill';

export default {
  components: { SkillLevel },
  props: ['value', 'type', 'formRef'],
  data() {
    const sortedSoftware = sortArray(Software, 'name');
    return {
      search: '',
      options: this.type === SkillType.LANGUAGES ? Languages : sortedSoftware,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    filteredSkills() {
      return this.options.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },
  methods: {
    levelChangedHandler(skill) {
      const index = this.value.findIndex((i) => i.name === skill.name);
      const updated = this.value[index];
      updated.level = skill.level;
      this.value[index] = updated;
    },
    skillCheckboxChanged(selected, skill) {
      const index = this.value.findIndex((i) => i.name === skill.name);
      if (selected) {
        const item = {
          name: skill.name,
          code: skill.code,
          level: 1,
        };
        if (index > -1) {
          this.value[index] = item;
        } else {
          this.value.push(item);
        }
        this.formRef.validate();
      } else {
        this.value.splice(index, 1);
      }
    },
    isSelected(skill) {
      return this.value
        ? this.value.some((item) => item.name === skill.name)
        : false;
    },
    subheaderNeeded(skill, index) {
      if (index > 0) {
        return skill.name[0] !== this.filteredSkills[index - 1].name[0];
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.skill-list-mobile
  max-height: calc(100vh - 180px) !important

.skill-list-desktop
  overflow-y: scroll
  max-height: calc(100vh - 360px)
</style>
