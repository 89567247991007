<template lang="pug">
.d-flex.justify-start.align-center.pl-3
  v-rating.d-flex(:value='value.level', @input="levelChanged"  :readonly='readonly' hover)
    template(v-slot:item='props')
      v-chip.mr-1.mr-sm-2.ratingChip( @click="props.click" :color="props.isFilled ? 'accent' : 'grey lighten-1'")

</template>

<script>
import SkillLevel from './SkillLevel';
import { cloneDeep } from 'lodash';
export default {
  components: { SkillLevel },
  props: ['value', 'readonly'],
  computed: {},
  methods: {
    levelChanged(props) {
      if (this.readonly) return;
      const updatedLevel = cloneDeep(this.value);
      updatedLevel.level = props;
      this.$emit('input', updatedLevel);
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/style/main'
.ratingChip
  max-width: 40px
  width: 100%
  max-height: 7px
  border-radius: 20px !important
</style>
