<template lang="pug">
v-card-text.pt-8.px-4
  InputRow(title="Status" icon="$country" v-if="isVisible('status')")
    v-chip-group(slot="inputSlot" multiple v-model='educationItem.status' )
      v-chip.status-chip.status-chip-select(v-for='chip in educationStatus' :class="{'error-status': !valid && (!educationItem.status || educationItem.status.length == 0) }"  :value="chip" :key='chip' active-class='status-chip-selected' mandatory)
        | {{chip}}
    v-text-field.d-none(slot="inputSlot" label="Status" dense hide-details readonly outlined v-model='educationItem.status' required :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']")
  //- GRADUATION
  InputRow(title="Abschluss" v-if="isVisible('graduation')")
    SelectDialog(slot="inputSlot" v-if="graduations"  label="Abschluss"  :items='graduations' v-model='educationItem.graduation' :multiple="true"   :required="true" :rules="[v => ( v && v.length > 0) || 'Pflichtfeld']")
  //- AREAS
  InputRow(title="Bereich" icon="$area" v-if="isVisible('areas')")
    SelectAreaDialog(slot="inputSlot" :areaItems="educationItem.areas" @areaChanged="areaItemsChanged" :required="true")
</template>

<script>
import Vue from 'vue';
import InputRow from '@/components/inputs/InputRow';
import SelectAreaDialog from '@/components/dialogs/SelectAreaDialog.vue';
import { cloneDeep } from 'lodash';
import { filterSuggestions } from '@/assets/selections/graduation';
import { showAttribute, EducationStatus } from '@/types/education';
import SelectDialog from '@/components/dialogs/SelectDialog.vue';

export default Vue.extend({
  components: { InputRow, SelectAreaDialog, SelectDialog },
  props: ['value', 'type', 'indexToUpdate', 'valid'],
  data() {
    return {
      index: cloneDeep(this.indexToUpdate),
      educationStatus: EducationStatus,
      educationItem: this.value[this.indexToUpdate]
        ? cloneDeep(this.value[this.indexToUpdate])
        : {
            level: [],
            graduation: [],
            averageGrade: 0,
            status: undefined,
            graduationYear: undefined,
            type: this.type,
            areas: [],
          },
    };
  },
  computed: {
    graduations() {
      return filterSuggestions(this.educationItem, true);
    },
  },
  methods: {
    isVisible(row) {
      return showAttribute(this.educationItem, row);
    },
    areaItemsChanged(areas) {
      this.educationItem.areas = areas;
    },
  },
  watch: {
    educationItem: {
      handler() {
        const updatedItem = cloneDeep(this.educationItem);
        if (updatedItem.averageGrade < 1) {
          updatedItem.averageGrade = undefined;
        }

        // remove invisible form values
        if (!this.isVisible('title')) updatedItem.title = null;
        if (!this.isVisible('level')) updatedItem.level = [];
        if (!this.isVisible('status')) updatedItem.status = null;
        if (!this.isVisible('graduationYear'))
          updatedItem.graduationYear = null;
        if (!this.isVisible('country')) updatedItem.country = null;
        if (!this.isVisible('graduation')) updatedItem.graduation = [];
        if (!this.isVisible('averageGrade')) updatedItem.averageGrade = null;
        if (!this.isVisible('areas')) updatedItem.areas = [];

        if (this.value[this.index]) {
          this.value[this.index] = updatedItem;
        } else {
          const newLength = this.value.push(updatedItem);
          this.index = newLength - 1;
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"

.status-chip-select
  background-color: inherit !important
  color: gray !important
  border: solid gray 2px !important
  border-radius: 7px !important
  padding: 0px 10px 0px 10px !important
  font-size: 10px !important
  height: 20px !important
  font-weight: 700 !important
  text-transform: uppercase
  &.error-status
    color: var(--v-error-base) !important
    border: solid var(--v-error-base) 2px !important

.status-chip-selected
  color: white !important
  background-color: gray !important
</style>
