<template lang="pug">

v-card.d-flex.flex-column.onboarding-card(:style="{ height: windowHeight + 'px' }")
  v-toolbar.flex-shrink-1.px-md-4.dialogToolbar(flat)
    .d-flex.flex-row.align-sm-center
      .headerIcon.d-flex.text-left.text-md-center.mb-0.pb-0.mr-4
        v-icon.headerIcon(size='40px', color='accent') {{currentStep < 8 ? "$conditions" : "$offerings" }}
      div
        h2 
          | {{currentStep < 8 ? "Wir suchen" : "Wir bieten"}}
          span.ml-2(style="color: grey; font-weight: 400") {{ currentStep }}/{{ stepCount}}
    v-spacer 
    v-toolbar-items
      v-btn(icon, color="accent" @click='cancelOnboarding')
        v-icon mdi-close
  v-card-text.pt-8.px-0.px-md-4.flex-grow-1.pb-12#layout-scroll-container(style="overflow-y: auto" v-if="newPosition")
      .profileQuestionnaire.scroll-wrapper.pb-8.pt-4.full-height.mx-auto.d-flex
        div.full-width
          v-toolbar-title(style="white-space: unset;")
            h2.text-center.mb-2(style="color: black") {{getTitle(currentStep)}}
          hr.accentHr

          div(v-if='currentStep == 1')
            v-form(lazy-validation :ref="forms[0].ref" v-model="forms[0].valid" :key="forms[0].formKey")
              UpdateEducationForm(v-model="newPosition.school" indexToUpdate="0" :type='EducationType.SCHOOL' :valid="forms[0].valid")
          div(v-if='currentStep == 2')
            v-form(lazy-validation :ref="forms[1].ref" v-model="forms[1].valid" :key="forms[1].formKey")
              UpdateEducationForm(v-model="newPosition.education" indexToUpdate="0" :type='EducationType.EDUCATION' :valid="forms[1].valid")
          div(v-if='currentStep == 3')
            v-form(lazy-validation :ref="forms[2].ref" v-model="forms[2].valid" :key="forms[2].formKey")
              UpdateExperienceForm(v-model="newPosition.practicalExperience" indexToUpdate="0" :type='ExperienceType.PRACTICAL_EXPERIENCE' :valid="forms[2].valid" :formRef="forms[2].ref")
          div(v-if='currentStep == 4')
            v-form(lazy-validation :ref="forms[3].ref" v-model="forms[3].valid" :key="forms[3].formKey")
              UpdateExperienceForm(v-model="newPosition.otherExperience" indexToUpdate="0" :type='ExperienceType.OTHER_EXPERIENCE' :valid="forms[3].valid" :formRef="forms[3].ref")
          div(v-if='currentStep == 5')
            v-form(lazy-validation :ref="forms[4].ref" v-model="forms[4].valid" :key="forms[4].formKey")
              UpdateSkillForm(v-model="newPosition.languages" :type='SkillType.LANGUAGES' :formRef="$refs[forms[4].ref]")
          div(v-if='currentStep == 6')
            v-form(lazy-validation :ref="forms[5].ref" v-model="forms[5].valid" :key="forms[5].formKey")
              UpdateSkillForm(v-model="newPosition.softwareSkills" :type='SkillType.SOFTWARE' :formRef="$refs[forms[5].ref]")
          div(v-if='currentStep == 7')
            v-form(lazy-validation :ref="forms[6].ref" v-model="forms[6].valid" :key="forms[6].formKey")
              UpdateOtherRequirementsForm(v-model="newPosition.otherRequirements" :initialNewElement="otherRequirementNewElement" @newElementChanged="otherRequirementNewElementChanged")
          div(v-if='currentStep == 8')
            v-form(lazy-validation :ref="forms[7].ref" v-model="forms[7].valid" :key="forms[7].formKey")
              UpdateConditionsForm(v-model="newPosition.offering")
          div(v-if='currentStep == 9')
            v-form(lazy-validation :ref="forms[8].ref" v-model="forms[8].valid" :key="forms[8].formKey")
              UpdateTasksForm(v-model="newPosition.tasks" :initialNewElement="taskNewElement" @newElementChanged="taskNewElementChanged")
          div(v-if='currentStep == 10')
            v-form(lazy-validation :ref="forms[9].ref" v-model="forms[9].valid" :key="forms[9].formKey")
              UpdateCompanyForm(v-model="newPosition.company")
          div(v-if='currentStep == 11')
            v-form(lazy-validation :ref="forms[10].ref" v-model="forms[10].valid" :key="forms[10].formKey")
              UpdateRecruiterForm(v-model="newPosition.recruiter")


  v-card-actions.justify-space-between.justify-md-center.dialogFooter 

    
    
    v-btn.text-center.mx-md-2(
        @click='prevStep',
        color="accent"
        depressed
        icon
        large
        :disabled="currentStep === 1"
    ) 
        v-icon(large) $leftArrow
    //- v-spacer.d-flex.d-md-none
    v-btn.text-center.mx-md-2(
        v-if='currentStep !== 11' 
        @click='skipStep',
        color="accent"
        depressed
        large
        text
        :disabled="!showSkipBtn"
        ) 
      span(style="font-size: 16px;") Überspringen
    v-btn.text-center.mx-md-2(
        v-if='currentStep !== 11' 
        @click='nextStep',
        depressed
        color="accent"
        icon
        large
        :disabled="currentStep === 11 || !forms[currentIndex].valid"
    ) 
        v-icon(large) $rightArrow
    v-btn.text-center.accent( v-else depressed @click='nextStep' :loading="loading" :disabled="!forms[currentIndex].valid")
      | Fertigstellen
    //- v-spacer.d-none.d-md-flex

  
  

</template>
<script>
import Vue from 'vue';

import { EducationType } from '@/types/education';
import { ExperienceType } from '@/types/experience';
import { cloneDeep, isEqual } from 'lodash';
import { SkillType } from '@/types/skill';

import UpdateCompanyForm from '@/components/business/offering/company/UpdateCompanyForm.vue';
import UpdateEducationForm from '@/components/business/lookingFor/educations/UpdateEducationForm.vue';
import UpdateExperienceForm from '@/components/business/lookingFor/experiences/UpdateExperienceForm.vue';
import UpdateSkillForm from '@/components/business/lookingFor/skills/UpdateSkillForm.vue';
import UpdateConditionsForm from '@/components/business/offering/conditions/UpdateConditionsForm.vue';
import UpdateOtherRequirementsForm from '@/components/business/lookingFor/other/UpdateOtherRequirementsForm.vue';
import UpdateTasksForm from '@/components/business/offering/tasks/UpdateTasksForm.vue';
import UpdateRecruiterForm from '@/components/business/offering/recruiter/UpdateRecruiterForm.vue';

export default Vue.extend({
  components: {
    UpdateConditionsForm,
    UpdateCompanyForm,
    UpdateEducationForm,
    UpdateExperienceForm,
    UpdateSkillForm,
    UpdateOtherRequirementsForm,
    UpdateTasksForm,
    UpdateRecruiterForm,
  },
  data() {
    return {
      stepCount: 11,
      EducationType,
      ExperienceType,
      forms: [
        {
          ref: 'schoolForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'educationForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'practicalExperienceForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'otherExperienceForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'languagesForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'softwareForm',
          valid: true,
          formKey: Math.random(),
        },
        {
          ref: 'otherRequirementsForm',
          valid: true,
          formKey: Math.random(),
        },
        {
          ref: 'offeringForm',
          formKey: Math.random(),
          valid: true,
        },
        {
          ref: 'tasksForm',
          formKey: Math.random(),
          valid: true,
        },
        {
          ref: 'companyForm',
          formKey: Math.random(),
          valid: true,
        },
        {
          ref: 'recruiterForm ',
          formKey: Math.random(),
          valid: true,
        },
      ],
      windowHeight: window.innerHeight,
      SkillType,
      loading: false,
      closeOnboardingWithoutAlert: false,
      otherRequirementNewElement: '',
      taskNewElement: '',
      newPosition: {
        name: this.$route.query.name,
        tasks: [],
        school: [],
        education: [],
        practicalExperience: [],
        otherExperience: [],
        offering: { title: this.$route.query.name },
        languages: [],
        softwareSkills: [],
        otherRequirements: [],
        company: cloneDeep(this.$store.state.business.company),
        recruiter: cloneDeep(this.$store.state.user.currentUser),
      },
    };
  },
  created() {
    if (!this.$route.query.name) {
      this.closeOnboardingWithoutAlert = true;
      this.cancelOnboarding();
    }
  },
  computed: {
    currentStep() {
      return Number(this.$route.query.step) || 1;
    },
    currentIndex() {
      return this.currentStep - 1;
    },
    showSkipBtn() {
      return ![1, 5, 8, 10, 11].includes(this.currentStep);
    },
  },
  methods: {
    scrollToTop() {
      const layoutId = document.getElementById('layout-scroll-container');
      if (layoutId) {
        layoutId.scrollTop = 0;
      }
    },
    getTitle() {
      switch (this.currentStep) {
        case 1:
          return 'Gewünschte Schule';
        case 2:
          return 'Gewünschte(s) Ausbildung / Studium';
        case 3:
          return 'Gewünschte Praxiserfahrung';
        case 4:
          return 'Gewünschte Auslandserfahrung';
        case 5:
          return 'Gewünschte Sprachen';
        case 6:
          return 'Gewünschte Software';
        case 7:
          return 'Weitere Anforderungen';
        case 8:
          return 'Tätigkeit';
        case 9:
          return 'Aufgaben';
        case 10:
          return 'Unternehmen';
        case 11:
          return 'Recruiter/in';
        default:
          return '';
      }
    },
    cancelOnboarding() {
      this.$router.push({ name: 'OfferingsBusiness' });
    },
    hideDialog() {
      this.$emit('closeDialog');
    },
    async savePosition() {
      try {
        this.loading = true;

        const offering = this.newPosition.offering;
        this.newPosition.offering = {
          jobType: [offering.jobType],
          startDate: offering.startDate,
          workingTime: [offering.workingTime],
          duration: [offering.duration],
          nationality: offering.nationality,
          city: offering.city,
          level: [offering.level],
          desiredSalary: offering.desiredSalary,
          vacationDays: offering.vacationDays,
          benefits: offering.benefits,
          areas: offering.areas,
        };

        const companyUpdated = !isEqual(
          this.newPosition.company,
          this.$store.state.business.company
        );

        if (companyUpdated) {
          // update company
          await this.$store.dispatch(
            'business/updateCompany',
            this.newPosition.company
          );
        }

        const recruiterUpdated = !isEqual(
          this.newPosition.recruiter,
          this.$store.state.user.currentUser
        );

        if (recruiterUpdated) {
          // update recruiter
          await this.$store.dispatch(
            'user/updateUser',
            this.newPosition.recruiter
          );
        }

        if (this.otherRequirementNewElement) {
          this.newPosition.otherRequirements.push(
            this.otherRequirementNewElement
          );
        }

        // create position
        await this.$store.dispatch('business/createPosition', this.newPosition);
        this.closeOnboardingWithoutAlert = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    prevStep() {
      this.$router.push({ query: { step: this.currentStep - 1 } });
      this.scrollToTop();
    },
    async nextStep() {
      if (this.otherRequirementNewElement) {
        this.newPosition.otherRequirements.push(
          this.otherRequirementNewElement
        );
        this.otherRequirementNewElement = '';
      }

      if (this.taskNewElement) {
        this.newPosition.tasks.push(this.taskNewElement);
        this.taskNewElement = '';
      }

      const currentRef = this.forms[this.currentIndex].ref;
      if (!this.$refs[currentRef].validate()) {
        return;
      }

      this.newPosition.name = this.newPosition.offering.title;

      if (this.currentStep < 11) {
        this.$router.push({ query: { step: this.currentStep + 1 } });
      } else {
        await this.savePosition();
        this.$router.push({
          name: 'MatchesBusiness',
          query: { positionId: this.$store.state.business.selectedPositionId },
        });
      }
      this.scrollToTop();
    },
    skipStep() {
      // remove data from current step if skipped
      switch (this.currentStep) {
        case 1:
          this.newPosition.school = [];
          break;
        case 2:
          this.newPosition.education = [];
          break;
        case 3:
          this.newPosition.practicalExperience = [];
          break;
        case 4:
          this.newPosition.otherExperience = [];
          break;
        case 5:
          this.newPosition.languages = [];
          break;
        case 6:
          this.newPosition.softwareSkills = [];
          break;
        case 7:
          this.newPosition.otherRequirements = [];
          this.otherRequirementNewElement = '';
          break;
        case 8:
          this.newPosition.offering = [];
          break;
        case 9:
          this.newPosition.tasks = [];
          this.taskNewElement = '';
          break;
        case 10:
          this.newPosition.company = cloneDeep(
            this.$store.state.business.company
          );
          break;
        case 11:
          this.newPosition.recruiter = cloneDeep(
            this.$store.state.user.currentUser
          );
          break;
        default:
          break;
      }

      this.$router.push({ query: { step: this.currentStep + 1 } });
      this.scrollToTop();
    },
    checkLeavingModal(next) {
      this.$root.$confirm({
        title: 'Wirklich abbrechen?',
        message: 'Möchten Sie das Anlegen der Stelle wirklich abbrechen?',
        options: {
          btnDisagree: 'Nein, fortsetzen',
          btnAgree: 'Ja, abbrechen',
        },
        agree: () => next(),
        disagree: () => next(false),
      });
    },

    preventNavigation(event) {
      if (!this.closeOnboardingWithoutAlert) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    otherRequirementNewElementChanged(newElement) {
      this.otherRequirementNewElement = newElement;
    },
    taskNewElementChanged(newElement) {
      this.taskNewElement = newElement;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNavigation);
    window.addEventListener('resize', this.onResize);
    document.querySelector('.v-application--wrap').style.minHeight =
      'fit-content';
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNavigation);
    window.removeEventListener('resize', this.onResize);
    document.querySelector('.v-application--wrap').style.minHeight = '100vh';
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.closeOnboardingWithoutAlert) {
      this.checkLeavingModal(next);
    } else {
      next();
    }
  },
});
</script>
<style lang="sass">
@import "@/assets/style/main"
.onboarding-card
  width: 100%
.profileQuestionnaire
  width: 100%
  max-width: 940px
.dialogToolbar
  flex: 0 1 auto
svg:hover .icon
    fill: blue
</style>
